<template>
  <div class="P-container">
    <BaseImg title="Products & Solutions" bgName="bg_products.png"> </BaseImg>
    <BaseProducts></BaseProducts>
    <div>
      <div class="">
        <div class="box">
          <div class="text">
            <div class="title">How much can you save?</div>
            <div class="date margin26">On 31 August 2018</div>
            <div class="date margin35">
              If you wanted $5000 AUD <br>At the bank you’d have to pay $5607.37<br> But
              at Skyline you only have to pay $5497.69
            </div>
            <div class="gold-title margin26">That’s a saving of $114.68!</div>
          </div>
          <div class="text">
            <div class="title">Still not convinced?</div>
            <div class="date margin26">On 31 August 2018</div>
            <div class="date margin35">
              If you wanted $10,000 USD<br> At the bank you’d have to pay $15,462.35
              NZD<br> But at Skyline you only have to pay $15,167.60 NZD
            </div>
            <div class="gold-title margin26">That’s $294.75 difference!</div>
          </div>
        </div>
      </div>
    </div>
    <BaseBusiness :isBgGray="true"></BaseBusiness>
  </div>
</template>

<script>
import BaseImg from '@/components/BaseImg.vue'
import BaseProducts from '@/components/BaseProducts.vue'
import BaseBusiness from '@/components/BaseBusiness.vue'

export default {
  components: {
    BaseImg,
    BaseProducts,
    BaseBusiness
  }
}
</script>

<style scoped>

.box {
  width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 100px;
}
.text {
  width: 600px;
}
.gold-title {
  font-size: 24px;
  font-weight: 500;
  color: #bc9a41;
}
.title {
  font-size: 32px;
  font-weight: 600;
  color: #163257;
}
.date {
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
}
.margin26 {
  margin-top: 26px;
}
.margin35 {
  margin-top: 35px;
}
@media only screen and (max-width: 600px) {
.box {
  width: 100vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 5vw;
}
.text {
  width: 50%;
}
.gold-title {
  font-size: 1rem;
  font-weight: 500;
  color: #bc9a41;
}
.title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #163257;
}
.date {
  font-size: 0.75rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.2rem;
}
.margin26 {
  margin-top: 2vw;
}
.margin35 {
  margin-top: 3vw;
}
}
</style>
