<template>
  <div class=" bg-gray-main">
    <div class=" sm:pt-126px sm:pr-100px sm:pl-100px sm:pb-137px flex flex-wrap justify-between m-auto sm:max-w-5xl w-100vw p-10vw">
      <div class="supporters-unit">
        <img
          class="supporters-img"
          src="@/assets/img/newFSCL.png"
          alt=""
        />
        <div class="supporters-name">FINANCIAL SERVICE COMPLAINTS LTD (FSCL)</div>
        <div class="supporters-number">NUMBER: 8659</div>
      </div>
      <div class="supporters-unit">
        <img
          class="supporters-img"
          src="@/assets/img/financial_service.png"
          alt=""
        />
        <div class="supporters-name">FINANCIAL SERVICE PROVIDER REGISTER</div>
        <div class="supporters-number">NUMBER: FSP1000612</div>
      </div>
      <div class="supporters-unit">
        <img class="supporters-img" src="@/assets/img/taiwhenua.png" alt="" />
        <div class="supporters-name">
          DEPARTMENT OF INTERNAL AFFAIRS (DIA)
        </div>
      </div>
      <div class="supporters-unit">
        <img class="supporters-img" src="@/assets/img/acams.png" alt="" />
        <div class="supporters-name">
          ACAMS
        </div>
      </div>
      <div class="supporters-unit">
        <img class="supporters-img" src="@/assets/img/cfcs.png" alt="" />
        <div class="supporters-name">
          ASSOCIATION OF CERTIFIED FINANCIAL CRIME SPECIALISTS
        </div>
      </div>
      <div class="supporters-unit">
        <img class="supporters-img" src="@/assets/img/ica.png" alt="" />
        <div class="supporters-name">
          INTERNATIONAL COMPLIANCE ASSOCIATION
        </div>
        <!-- <div class="supporters-number">NUMBER: FSCL 4377</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {

}

</script>

<style scoped>
.supporters-img{
   border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  object-fit: contain;
}
.supporters-img:hover {
  box-shadow: 0 0 2px 1px #163257;
}
</style>
