<template>
    <div class="settings-container">
        <div class="settings-title">Settings</div>
        <div class="settings-content">
            <a-collapse v-model="activeKey">
                <a-collapse-panel
                    v-if='info_data.referrer_type === "I"'
                    key="1"
                    header="IR330C表格"
                    class="collapse-header"
                >
                    <a-steps
                        :current="Ird_steps" direction="vertical"
                    >
                        <a-step
                            :disabled='true'
                            title="Download and Fill the form"
                        >
                            <a class=" underline font-500" slot="description" @click.stop="handleDownloadFile">Click to Download Form</a>
                        </a-step>
                        <a-step
                            :disabled='true'
                            @click.stop
                            title="Uploading"
                        >
                            <AUploadFile
                                :disabled='uploadDisable'
                                title="IR330C_Form"
                                :fileList="getIrdFileList"
                                prop='ird_file_list'
                                @handleFileChange="handleFileList"
                                slot="description"/>
                        </a-step>
                        <a-step
                            :status="irdStatus[getIrdStatus].status"
                            :disabled='true'
                            :title="irdStatus[getIrdStatus].title" :description="irdStatus[getIrdStatus].desc"
                        >
                            <a-icon v-if="irdStatus[getIrdStatus].showIcon" slot="icon" :type="irdStatus[getIrdStatus].icon" />
                        </a-step>
                    </a-steps>
                    <a-col :sm="24" :md="12" class="uploaded-content">
                    </a-col>
                </a-collapse-panel>
                 <a-collapse-panel
                    v-else
                    key="1"
                    header="Invoice"
                    class="collapse-header"
                >
                    <a-steps
                        :current="Ird_steps" direction="vertical"
                    >
                        <a-step
                            :disabled='true'
                            @click.stop
                            title="上传公司银行账号截图"
                            subtitle='sda'
                        >
                            <AUploadFile
                                :disabled='getInvoiceStatus === "success"'
                                title="必须显示公司tilte和公司账号"
                                :fileList="getInvoiceFileList"
                                prop='invoice_file_list'
                                @handleFileChange="handleFileList"
                                slot="description"/>
                        </a-step>
                        <a-step
                            :status="irdStatus[getInvoiceStatus].status"
                            :disabled='true'
                            :title="irdStatus[getInvoiceStatus].title" :description="irdStatus[getInvoiceStatus].desc"
                        >
                            <a-icon v-if="irdStatus[getInvoiceStatus].showIcon" slot="icon" :type="irdStatus[getInvoiceStatus].icon" />
                        </a-step>
                    </a-steps>
                    <a-col :sm="24" :md="12" class="uploaded-content">
                    </a-col>
                </a-collapse-panel>
                <a-collapse-panel
                    key="2"
                    header="提现账号"
                    class="collapse-header"
                >
                    <div class="collapse-content">
                        <a-row class="mt-4" :gutter="10">
                            <a-col :xs="12" :md="6">
                                <div class="mb-2 pl-1">所属银行</div>
                                <a-input
                                :value="getBankAccount.bank_name"
                                disabled placeholder="银行名" />
                            </a-col>
                            <a-col :xs="12" :md="6" class="account-input">
                                <div class="mb-2 pl-1">账户名</div>
                                <a-input
                                :value="getBankAccount.account_name"
                                disabled placeholder="账户名" />
                            </a-col>
                        </a-row>
                        <div class="mb-2 pl-1 mt-4">现有账户:</div>
                        <span class="account-number">{{getBankAccount.account_number}}</span>
                        <div class="mt-4">
                            <a-button
                                class="h-10 sm:float-none float-right mb-4"
                                type="primary"
                                @click="showEditAccountModal"
                            >
                                修改/添加 账户
                            </a-button>
                        </div>
                    </div>
                </a-collapse-panel>
            </a-collapse>
        </div>

        <a-modal
            title="修改提现账户"
            :visible="editAccountModalVisible"
            @cancel="handleEditAccountCancel"
            okText="提交"
            @ok="handleBankSubmit"
        >
            <a-form-model ref="bankForm" :model="bankForm" :rules="rules">
                <a-row :gutter="10">
                    <a-col :xs="12" :md="12">
                        <a-form-model-item label="所属银行" prop="bank_name">
                            <a-input v-model="bankForm.bank_name"/>
                        </a-form-model-item>
                    </a-col>
                    <a-col :xs="12" :md="12">
                        <a-form-model-item label="账户名" prop="account_name">
                            <a-input  v-model="bankForm.account_name"/>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="10">
                    <a-col :span="24">
                        <a-form-model-item label="银行账号" prop="account_number">
                            <BaseBankAccount
                                @handleInpuChange="(e)=>{
                                  bankForm.account_number=e
                                }"
                                :account="bankForm.account_number  "/>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import fileDownLoad from '@/utils/download.js'
import BaseBankAccount from '@/components/BaseBankAccount.vue'
// eslint-disable-next-line no-unused-vars
import { brokerAddBank, brokerIrdSubmit, brokerInvoiceSubmit } from '@/api/common.js'
import rules from '@/assets/rules/bankRules.js'
import AUploadFile from '@/components/AUploadFile.vue'

export default {
  components: {
    BaseBankAccount,
    AUploadFile
  },
  created () {
    this.fileDownLoad = fileDownLoad
    this.rules = rules
  },
  data () {
    return {
      status: 'wait',
      irdStatus: {
        none: {
          status: 'process',
          title: 'Waiting',
          desc: 'Please Upload the file',
          showIcon: false,
          icon: 'none'
        },
        wait: {
          status: 'wait',
          title: 'Pending',
          desc: 'Waiting for our Finance to approve your application',
          showIcon: true,
          icon: 'loading'
        },
        success: {
          status: 'finish',
          title: 'Approved',
          desc: 'You can withdraw money Now!',
          showIcon: true,
          icon: 'check-circle'
        }
      },

      Ird_steps: 0,
      href: 'https://public-nzsky-files.s3-ap-southeast-2.amazonaws.com/IR330C.pdf',
      activeKey: [1],
      headers: {
        authorization: 'authorization-text'
      },
      bankForm: {
        bank_name: '',
        account_name: '',
        account_number: '---'
      },
      editAccountModalVisible: false
    }
  },
  methods: {
    ...mapActions('broker', ['SetBrokerAction']),
    handleDownloadFile () {
      this.Ird_steps = 1
      fileDownLoad(this.href)
    },
    handleFileList (fileList, content) {
      if (fileList === null) {
        return
      }
      if (fileList !== null) {
        if (fileList.length > 1) {
          fileList = fileList.slice(fileList.length - 1, fileList.length)
        }
      }
      const query = {
        [content]: fileList
      }
      if (content === 'ird_file_list') {
        this.Ird_steps = 2
        brokerIrdSubmit(query).then(res => {
          this.SetBrokerAction()
          this.$message.success('上传成功')
        })
      } else if (content === 'invoice_file_list') {
        brokerInvoiceSubmit(query).then(res => {
          this.SetBrokerAction()
          this.$message.success('上传成功')
        })
      }
    },
    handleChange (info) {
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`)
      }
    },
    showEditAccountModal () {
      this.editAccountModalVisible = true
    },
    handleEditAccountCancel (e) {
      this.editAccountModalVisible = false
    },
    handleBankSubmit (e) {
      this.$refs.bankForm.validate(valid => {
        if (valid) {
          brokerAddBank(this.bankForm).then((res) => {
            this.$message.success('提交成功')
            this.SetBrokerAction()
            this.editAccountModalVisible = false
          }).catch(() => {
            this.$message.error('Server is Busy')
          })
        } else {
          return false
        }
      })
      // this.editAccountModalVisible = false
    }
  },
  watch: {
  },
  computed: {
    uploadDisable () {
      if (this.getIrdStatus) {
        if (this.getIrdStatus === 'success') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    ...mapState('broker', ['info_data']),
    ...mapGetters('broker', ['getBankAccount', 'getIrdStatus', 'getIrdFileList', 'getInvoiceFileList', 'getInvoiceStatus'])
  }
}
</script>

<style scoped>
.settings-title {
    padding: 30px;
    background: #ffffff;
    font-size: 24px;
    font-weight: 400;
    color: #080808;
    line-height: 28px;
}
.settings-content {
    padding: 10px 50px;
    margin: 30px 0px;
}
.collapse-header {
    background-color: #fbfbfb;
}
.collapse-content {
    padding: 20px;
}
.uploaded-file {
    margin-left: 20px;
    color: #008dff;
    text-decoration: underline;
}
.account-info {
    font-size: 16px;
}
.account-number {
    margin-left: 10px;
    color: red;
    font-size: 18px;
}
.edit-password-lable {
    font-size: 14px;
    text-align: right;
    line-height: 32px;
}
.password-input {
    width: 400px;
}
.new-password {
    margin-top: 14px;
}
.edit-password-btn {
    margin-top: 30px;
    margin-left: 156px;
    width: 140px;
    height: 40px;
}
.uploaded-content {
    font-size: 16px;
}
@media only screen and (max-width: 600px) {
    .settings-content {
        padding: 10px;
        margin: 10px 0px;
    }
    .collapse-content {
        padding: 20px 6px;
    }
    .uploaded-content {
        margin-top: 20px;
        font-size: 16px;
    }
    .password-input {
        width: 200px;
    }
    .edit-password-btn {
        margin-left: 90px;
    }
}
</style>
