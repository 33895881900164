<template>
  <div>
    <BaseImg title="About Skyline" bgName="bg_about.png"> </BaseImg>
    <BaseAbout :isBgBlue="false"></BaseAbout>
    <BaseSupporters></BaseSupporters>
  </div>
</template>

<script>
import BaseImg from '@/components/BaseImg.vue'
import BaseAbout from '@/components/BaseAbout.vue'
import BaseSupporters from '@/components/BaseSupporters.vue'

export default {
  components: {
    BaseImg,
    BaseAbout,
    BaseSupporters
  }
}

</script>

<style scoped>
.supporters {
  padding: 126px 100px 137px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 0 auto;
  width: 1440px;
  background: #f5f5f5;
}
.supporters-unit {
  width: 280px;
  box-sizing: border-box;
}
.supporters-img {
  width: 100%;
  height: 186px;
}
.supporters-name {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 400;
  color: #163257;
  line-height: 25px;
  text-align: center;
}
.supporters-number {
  margin-top: 16px;
  font-size: 18px;
  font-weight: 400;
  color: #bc9a41;
  line-height: 25px;
  text-align: center;
}

.temp {
  background: #f5f5f5;
}@media only screen and (max-width: 768px) {
}
</style>
