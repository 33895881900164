<template>
  <div class="bg-gray-main">
      <div class="block sm:flex justify-between w-full sm:w-1440px sm:pt-73px sm:pr-100px sm:pb-101px sm:pl-52px p-5vw m-auto">
        <img
          class=" w-90vw sm:w-656px sm:h-446px"
          style="display: block"
          src="@/assets/img/img_productDisplay.png"
          alt=""
        />
        <div class="w-90vw sm:w-600px pt-0 sm:pt-47px">
          <div class=" hidden sm:block text-2xl sm:text-24px font-600 uppercase text-orange-main">Skyline</div>
          <div class=" text-2xl sm:text-48px font-600 text-blue-main   leading-3xl sm:leading-56px  sm:mt-6px mt-1vw uppercase">Personal</div>
          <div class="text-blue-main text-875rem sm:text-18px sm:mt-24px mt-3vw noto">
            Our Skyline team cares about all of your needs. Whether you are
            starting a new stage of your life oversea or coming to New Zealand,
            or sending money to a loved one, we've got you covered.
          </div>
          <button class="h-10vw px-5vw sm:px-40px sm:h-60px bg-blue-main text-white text-sm sm:text-22px text-center font-300 rounded-full leading-10vw sm:leading-60px sm:mt-62px mt-5vw activeBtn  noto" @click="handleJumptoSign">SEND MONEY NOW</button>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  methods: {
    handleJumptoSign () {
      this.$router.push({
        name: 'SmsLogin'
      })
    }
  }
}
</script>

<style scoped>
.activeBtn{
  transition: 0.1s ease-in-out;
  outline: none;
}
.activeBtn::after{
  transition: 0.1s ease-in-out;
}
.activeBtn:active{
  transform: translate( 1%, 1%);
}
</style>
