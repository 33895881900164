<template>
  <div class="whole">
    <BaseImg title="It pays to work with Skyline" bgName="bg_affiliates.png">
      <div>
        <div class="aff_subtitle marginTop24">The Skyline Affiliate Program offers you an easy and a safe way to get a guaranteed revenue share for every single purchase that your referrals make by using their ecoAccount.</div>
        <div class="aff_subtitle">Whether you are an individual, company or affiliate chain, you can join the Skyline Affiliate Program today.</div>
      </div>
      <template v-slot:btn>
        <div @click="RouteToForm" class="getStartedBtn">GET STARTED</div>
      </template>
    </BaseImg>
    <div class="aff-bottom">
      <AffItem
        title="Guaranteed revenue"
        sub_title="Get paid a guaranteed revenue share on all your referrals' purchases, with weekly payouts."
        imgSrc="icons_coins"
        delayTime='0s'
      />
      <AffItem
        title="Increased conversions"
        sub_title="Increase conversions through personalised landing pages and supported local payment options. "
        delayTime='.3s'
        imgSrc="icons_increase"
      />
      <AffItem
        title="Easy access to commission"
        sub_title="Spend your earnings anywhere, anytime with an Skyline Mastercard linked to your account, or an Skyline Business Mastercard linked to your Business Account*."
        imgSrc="icons_online_card"
        delayTime='.6s'
      />
      <AffItem
        title="Personal dedicated support"
        sub_title="Your own personal Skyline Affiliate Program Account Manager and premium support for your referrals."
        imgSrc="icons_user_support"
        delayTime='.9s'
      />
      <AffItem
        title="Brand resource"
        sub_title="Access to a range of texts, logos and reports to help kickstart your success as an Skyline Affiliate."
        imgSrc="icons_Marketing_resources"
        delayTime='1.2s'
      />
      <AffItem
        title="Flexible"
        sub_title="Whether you are an individual, company or affiliate chain you can apply for the Skyline Affiliate Program."
        imgSrc="icons_flex"
        delayTime='1.5s'
      />
    </div>
    <div class="aff_sub_bottom">
      <div class="aff_sub_bottom_container">
        <div class="half">
          <div class="aff_sub_bottom_title">
            Start making money
          </div>
          <div class="aff_sub_bottom_subtitle">
            Skyline is a trusted payment solutions provider offering instant, safe and convenient payment services across the globe, so referring your customers to us is simple.
          </div>
          <div class="aff_sub_bottom_subtitle">
            Becoming an affiliate is easy and with access to a personal Affiliate Manager to help get you started, we can help you grow and increase your revenue.
          </div>
          <div @click="RouteToForm" class="getStartedbom_botm">GET STARTED</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BaseImg from '@/components/BaseImg.vue'
import AffItem from '@/components/AffItem.vue'
export default {
  methods: {
    ...mapActions(['ShowReferrerLogin']),
    RouteToForm () {
      this.ShowReferrerLogin()
      this.$router.push({ name: 'SmsLogin' })
    }
  },
  components: {
    BaseImg,
    AffItem
  }
}
</script>

<style scoped>
.aff_subtitle{
  font-size: 16px;
  color: #eee;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 500;
  animation: showUp 0.6s ease-in-out 1 alternate forwards;
}
@keyframes showUp{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
.whole{
  color:rgb(103,103,103)
}
.aff-bottom {
  width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding: 80px 140px 100px 100px;
}
.goldeTitle {
  font-size: 24px;
  font-weight: 600;
  color: #bc9a41;
  line-height: 24px;
  text-transform: uppercase;
}
.marginTop64 {
  margin-top: 64px;
}
.marginTop24 {
  margin-top: 24px;
}
.marginTop35 {
  margin-top: 35px;
}
.general {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
}
.blueTitle {
  font-size: 24px;
  font-weight: 600;
  color: #163257;
}
.img {
  width: 1024px;
  height: 683px;
  display: block;
}
.getStartedBtn{
  position: absolute;
  bottom: 10%;
  left: calc(50% - 100px);
  width: 200px;
  background: #8cc23c;
  text-align: center;
  color: #fff;
  font-weight: 800;
  font-size: 18px;
  padding: 15px 10px;
  cursor:pointer;
  border-radius: 3px;
}
.getStartedbom_botm{
  width: 200px;
  background: #8cc23c;
  text-align: center;
  color: #fff;
  font-weight: 800;
  font-size: 18px;
  padding: 15px 10px;
  cursor:pointer;
  border-radius: 3px;
}
.aff_sub_bottom{
  background: url('../../..//assets/bg/aff_bg.jpeg');
  padding: 3.75em 0;
  background-position: fixed;
  min-height: 39em;
  overflow: hidden;
  background-position: center top;
  background-repeat: no-repeat;
}
.aff_sub_bottom_title{
  color:#fff;
  font-weight: 800;
  font-size: 36px;
  margin-bottom: 1.25em;
  padding-top: .625em;
  position: relative;
}
.aff_sub_bottom_title::before{
  position: absolute;
    left: 0;
    top: 0;
    width: 60px;
    height: 4px;
    background: #8cc23c;
    content: '';
}
.aff_sub_bottom_container{
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 2em;
}
.half{
  width: 50%;
  padding-right: 6em;
}
.aff_sub_bottom_subtitle{
  color:#eee;
  margin-bottom: 1.5em;
  font-size: 15px;
}
@media only screen and (max-width: 600px) {
  .aff_sub_bottom_title{
    color:#fff;
  font-weight: 800;
  font-size: 6.5vw;
  margin-bottom: 4vh;
  padding-top: .625em;
  position: relative;
  }
  .getStartedBtn{
  position: absolute;
  bottom: 15%;
  left: calc(50% - 18vw);
  width: 36vw;
  background: #8cc23c;
  text-align: center;
  color: #fff;
  font-weight: 800;
  font-size: 4vw;
  padding: 2vw 2vw;
  cursor:pointer;
  border-radius: 3px;
}
.getStartedbom_botm{
  width: 36vw;
  background: #8cc23c;
  text-align: center;
  color: #fff;
  font-weight: 800;
  font-size: 4vw;
  padding: 2vw 2vw;
  cursor:pointer;
  border-radius: 3px;
}
  .half{
    width: 100%;
    padding-right:1em
  }
.aff_subtitle{
  display: none;
}
.aff-bottom {
  width: 100%;
  margin: 0 auto;
  padding: 5vw;
}
.goldeTitle {
  font-size: 1.25rem;
  font-weight: 500;
  color: #bc9a41;
  line-height: 1.5rem;
  text-transform: uppercase;
}
.marginTop64 {
  margin-top: 4vw;
}
.marginTop24 {
  margin-top: 2vw;
}
.marginTop35 {
  margin-top: 3vw;
}
.general {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5rem;
}
.blueTitle {
  font-size: 1.5rem;
  font-weight: 500;
  color: #163257;
}
.img {
  width: 100%;
  height: auto;
  display: block;
}
}
</style>
