<template>
  <div :class="['flex w-full py-2 sm: justify-between items-center px-6 sm:px-8 text-xs sm:text-18px', combineBlueTrigger ? 'bg-blue' : 'bg-none']">
    <!-- <div :class="['flex w-full py-2 sm:pt-0 justify-between items-center px-6 sm:px-8 text-xs sm:text-18px', getCurrentUlr ? 'bg-blue' : 'bg-none']"> -->
      <img class="w-32 sm:w-64 cursor-pointer" src="@/assets/img/white_long_logo.png"
      @click="jumpHome"
       alt="" />
    <div class="flex items-center pt-2 sm:pt-3">
      <!-- <button class="font-300 p-1 cursor-pointer sm:p-5" @click="handleToggoleLanguage">{{getCurrentLanguage}}</button>
      <span class="top-divider">|</span> -->
      <!-- <span v-if="getCurrentPage !== 'SignUp'">
      <router-link
      to="/signup"
      class="font-300 p-1 cursor-pointer sm:p-5" >{{$t('common.Signup')}}</router-link>
      <span class="top-divider">|</span> -->
      <!-- </span> -->
      <span v-if="getCurrentPage !== 'LogIn'">
      <router-link to="/sms-login" class="font-300 p-1 cursor-pointer sm:p-5"
      >{{$t('common.Login')}}</router-link>
      <span class="top-divider">|</span>
      </span>
      <span
      @click="handelMenuClick"
      class="font-300 p-1 sm:p-5 cursor-pointer"
      >{{$t('common.Menu')}}</span>
      <img
        v-on:click="handelMenuClick"
        class="ml-3 sm:h-6 sm:w-8 h-3 w-4 cursor-pointer"
        :src = menuWhite
        alt=""
      />
    </div>
  </div>
</template>

<script>
import menuBlue from '@/assets/icon/menu_blue.png'
import menuWhite from '@/assets/icon/menu_white.png'

export default {
  data () {
    return {
      menuBlue,
      menuWhite
    }
  },

  props: {
    bgBlue: Boolean,
    blueTrigger: Boolean
  },
  computed: {
    combineBlueTrigger () {
      if (!this.blueTrigger) {
        return true
      } else {
        return this.bgBlue
      }
    },
    getCurrentLanguage () {
      const temp = this.$i18n.locale
      if (temp === 'zh') {
        return 'English'
      } else {
        return '中文'
      }
    },
    getCurrentPage () {
      const { name } = this.$route
      return name
    }
  },
  methods: {
    handleToggoleLanguage () {
      const temp = this.$i18n.locale
      temp === 'zh' ? this.$i18n.locale = 'en' : this.$i18n.locale = 'zh'
    },
    jumpHome () {
      const { name } = this.$route
      if (name === 'iemoney') {
        // eslint-disable-next-line no-useless-return
        return
      } else {
        this.$router.push({ name: 'homePage' })
      }
    },
    handelMenuClick () {
      this.$emit('MenuClick')
    }
  }
}
</script>

<style scoped>

.bg-none{
  background: none;
  color: white;
}
.cursor-pointer{
  cursor: pointer;
}
.right {
  display: flex;
  align-items: center;
}

.bg-blue {
  background: #163257;
  color:  #ffffff;
}
</style>
