<template>
  <div class="aff_sign_container">
    <div class="sign_content">
    <div class="title">Referral Agent Sign up</div>
    <form action="">
    <BaseInput
    placeHolder="Name"
    id_type="referral_name"
    i_type="text"
    :isAutoFocus='true'
    :input_value="referral_name"
    @handleUpdateValue="(val)=> referral_name= val"
    />
    <BaseInput
    placeHolder="Email"
    id_type="referral_email"
    i_type="text"
    :isAutoFocus='true'
    :input_value="referral_email"
    @handleUpdateValue="(val)=> referral_email= val"
    />
    <div class="flex justify-between">
      <BaseSelect
      :selectValue="default_dial_code"
      style="width:35%"
      ref="select"
      :list='dial_code_list'
      @handleItemSelect="handleItemSelect"
      />
      <BaseInput
      style="width:60%"
        placeHolder="Phone Number"
        id_type="referral_phone"
        i_type="text"
        :isAutoFocus='true'
        :input_value="referral_phone"
        @handleUpdateValue="(val)=> referral_phone= val"
        />
    </div>
    <BaseInput
      :regObj="[pwdReg]"
      id_type="pwd1"
      :i_type.sync="typePassword"
      @changeType="(val) => (typePassword = val)"
      :isPwd='true'
      placeHolder="Password"
      :input_value="pwd1"
      :customerProp="pwd2"
      :customerTriggle="true"
      :isProve.sync ='pwd1Pass'
      @handleUpdateValue="(val) => (pwd1 = val)"
    >
    </BaseInput>
    <BaseInput
      :isPwd='true'
      :regObj="[pwdReg]"
      :i_type.sync="typePassword"
      @changeType="(val) => (typePassword = val)"
      id_type="pwd2"
      placeHolder="Confirm Password"
      :input_value="pwd2"
      :customerTriggle="true"
      :customerProp="pwd1"
      :isProve.sync ='pwd2Pass'
      @handleUpdateValue="(val) => (pwd2 = val)"
    >
    </BaseInput>
    <button class=" mt-4 nextBtn" :disabled ='checkAllValid'
        @click="handleSignUp"> SUBMIT </button>
    </form>
  </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue'
import BaseSelect from '@/components/BaseSelect.vue'
import { emailReg, pwdReg } from '@/utils/validation.js'

export default {
  mounted () {
    document.getElementById('app').onclick = () => {
      if (this.$refs.select) {
        this.$refs.select.selectBlur()
      }
    }
  },
  components: {
    BaseInput,
    BaseSelect
  },
  data () {
    return {
      emailReg,
      pwdReg,
      emailPass: false,
      pwd1Pass: false,
      pwd2Pass: false,
      referral_phone: '',
      pwd1: '',
      pwd2: '',
      typePassword: 'password',
      referral_name: 'asd',
      referral_email: '',
      default_dial_code: {
        id: 3,
        country: 'New Zealand',
        code: '+64'
      },
      dial_code_list: [
        {
          id: 1,
          country: 'Australia',
          code: '+61'
        },
        {
          id: 2,
          country: 'China',
          code: '+86'
        },
        {
          id: 3,
          country: 'New Zealand',
          code: '+64'
        },
        {
          id: 4,
          country: 'Singapore',
          code: '+65'
        }
      ]
    }
  },
  methods: {
    handleItemSelect (item) {
      this.default_dial_code = item
    }
  },
  computed: {
    checkAllValid () {
      return true
    }
  },
  beforeDestroy () {
    document.getElementById('app').onclick = null
  }
}
</script>

<style scoped>
.aff_sign_container{
  padding-top: 127px;
  min-height: 100vh;
}
.sign_content{
  width: 480px;
  margin: 0 auto;
  padding: 1.5rem;
}.nextBtn{
  font-size: 20px;
  font-weight: 600;
  color: white;
  width: 100%;
  height: 56px;
  padding: 5px 20px;
  border-radius: 2px;
  background: #163257;
  letter-spacing: 4px;
}
.title{
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
}
.mutiple_form_layout {
  display: flex;
  justify-content: space-between;
}
.nextBtn:disabled{
  background: rgba(22, 50, 87, 0.2);
  cursor: not-allowed;
}
@media only screen and (max-width: 768px) {
  .mutiple_form_layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
