<template>
  <div class="policy-container">
    <div class="policy-header">
      <div class="policy-title">
        <h2 class="title">Terms of Service</h2>
      </div>
    </div>
    <div class="policy-content policy-content-top">
      <div class="title-border"></div>
      <h2 class="policy-content-title">OVERVIEW</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        This website is operated by SKYLINE TECH LIMITED. Throughout the site, the terms “we”, “us” and “our” refer to SKYLINE TECH LIMITED. SKYLINE TECH LIMITED offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        By visiting our site and/or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/or contributors of content.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">SECTION 1 – ONLINE STORE TERMS</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        You may not use our products for any illegal or unauthorised purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        You must not transmit any worms or viruses or any code of a destructive nature.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        A breach or violation of any of the Terms will result in an immediate termination of your Services.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">SECTION 2 – GENERAL CONDITIONS</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        We reserve the right to refuse service to anyone for any reason at any time.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        1. Description of Services<br/>
        • SKYLINE TECH LIMITED agrees to provide the following professional services to the client:<br/>
        • Provide for the exchange of New Zealand currency into foreign currencies and the exchange of foreign currencies into New Zealand currency. SKYLINE TECH LIMITED may use the services of Collinson Forex Limited to provide some or all of these services to the client<br/>
        • Dealing in non-cash payment products<br/>
        • Provide any relevant online information inquiry service
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        2. Confidentiality<br/>
        2.1. In completing this Application Form and Agreement for Services, you may supply SKYLINE TECH LIMITED with information which is personal information subject to New Zealand’s Privacy Act 1993 (as amended).<br/>
        2.2. As a general statement, SKYLINE TECH LIMITED agrees to keep any confidential data supplied by clients confidential and to make no disclosure thereof to any third party, except as required by law.<br/>
        2.3. You acknowledge that the information that we obtain about you will be used for the purposes of:<br/>
        (a) Assessing my/our current and on-going credit worthiness;<br/>
        (b) Providing and administering your trading/exchange account;<br/>
        (c) Maintaining the integrity and effectiveness of SKYLINE TECH LIMITED’s credit records, the credit records of any SKYLINE TECH LIMITED’s associated company, and those of credit reporting agencies;<br/>
        (d) Collecting, compiling and/or reviewing information, including both positive and negative information about your credit history and credit rating;<br/>
        (e) Marketing goods and services provided by the SKYLINE TECH LIMITED and its assigns or related companies unless you notify SKYLINE TECH LIMITED otherwise;<br/>
        (f) Ensuring that SKYLINE TECH LIMITED complies with all regulatory obligations under New Zealand law;<br/>
        (g) You are entitled to contact SKYLINE TECH LIMITED and request access to your Personal Information.<br/>
        2.4. You irrevocably authorise us for any and all of the foregoing purposes:<br/>
        (a) To obtain information that is reasonably required concerning us from any source including, but not limited to, sanctions screening agencies, credit reporting agencies, past and current credit providers, NZ Police. You hereby permit all of these parties to provide such information to SKYLINE TECH LIMITED;<br/>
        (b) To collect, retain and use the information, including for the purpose of e-mail and other electronic or social media marketing or communication services between you and SKYLINE TECH LIMITED and/or its assigns or related companies used in connection with the purposes outlined above;<br/>
        (c) In the event that this application is declined, to disclose the reasons for the decline to the agent or broker, as the case maybe;<br/>
        (d) To share any such information with Collinson Forex Limited.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        3. Written terms and conditions to prevail<br/>
        These terms and conditions apply to all of your Applications/Agreements with us, whenever those Application/Agreements were entered into. To the extent there is any inconsistency or conflict between these terms and conditions and any previous terms and conditions disclosed by us to you, these terms and conditions will apply and you agree, to the extent applicable, that adoption of these terms and conditions will constitute an agreed variation of any existing Applications/Agreements you have with us.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        4. Client Warranties<br/>
        Client is responsible for ensuring that the use of the services is in compliance with all applicable laws, including, without limitation, laws intended to prevent money-laundering, gaming and laws intended to protect consumers against unfair debt collection. Client and not SKYLINE TECH LIMITED shall be responsible for all taxes associated with the services and/or the transfer of any funds performed hereunder, including, without limitation, income tax, withholding taxes, value added taxes (VAT), goods and services taxes (GST), sales taxes, customs and excise duty and use taxes. If it is determined that SKYLINE TECH LIMITED may owe any such taxes, Client shall promptly reimburse SKYLINE TECH LIMITED therefore.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        5. Resolution of Customer Inquiries<br/>
        SKYLINE TECH LIMITED shall be responsible for responding to and resolving inquiries and complaints from Clients arising out of the use of SKYLINE TECH LIMITED’s services by Clients.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        6. Compliance with Laws<br/>
        Clients agree to indemnify SKYLINE TECH LIMITED and hold it harmless from any and all claims, losses, damages, liabilities or expenses(including attorney’s fees) of every nature and kind incurred as a result of Client’s failure or alleged failure to comply with applicable laws, orders, rules and regulations. Any act or omission in breach of any such law, order, rule or regulation shall constitute a material breach of this agreement. Client represents and warrants that all information disclosed to SKYLINE TECH LIMITED in connection with this Application Form and Agreement for Services, is true, accurate and complete. This representation and warranty shall be deemed an ongoing representation and warranty from Client.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        7. Indemnification/Limitation of Liability<br/>
        7.1. Client will defend, indemnify and hold harmless SKYLINE TECH LIMITED from any claims, damages or losses (“Losses”) relating to all services processed hereunder, except to the extent such Losses arise by reason of SKYLINE TECH LIMITED’s breach of the terms and conditions of this Application Form and Agreement for Services. In such an instance SKYLINE TECH LIMITED’s only responsibility for damages on error, delay, nonpayment, negligence (apart from SKYLINE TECH LIMITED responsibility to assure re-payment of the principal amount actually received from Client or a Customer), shall be limited to a refund of the service fee paid in connection with the affected transaction.<br/>
        7.2. Under no circumstances will SKYLINE TECH LIMITED be liable to Client or Customers for indirect, exemplary, incidental, special, or consequential damages, whether or not either party knew or had reason to know that they might be incurred. In no event shall SKYLINE TECH LIMITED’s aggregate liability to Client under this Agreement for any reason exceed the lesser of the amount of fees paid hereunder to SKYLINE TECH LIMITED during the sixth month period prior to when the claim arose or fifty thousand dollars ($50,000 NZD).<br/>
        7.3. In particular, the client agrees that where SKYLINE TECH LIMITED is unable to complete the provision of services to the client as a result of external events, (e.g. a collapse of financial services, the freezing of SKYLINE TECH LIMITED or its agents or associated companies bank accounts by any bank, government or authority, force majeure etc), SKYLINE TECH LIMITED’s only obligation is to repay an amount equivalent to the amount originally paid to SKYLINE TECH LIMITED, as and when it is reasonably able to do so.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        8. Terms and Termination<br/>
        SKYLINE TECH LIMITED may terminate or suspend this Application Form and Agreement for Services or any services to the client at any time without notice if SKYLINE TECH LIMITED determines in its sole discretion that the client is using the services to transmit funds<br/>
        (a) for gambling (including, without limitation, betting on sporting events) to or from New Zealand or any other jurisdiction where such transmission may be illegal;<br/>
        (b) in any way which may violate anti-money laundering laws of the New Zealand or any jurisdiction to or from which the money is transmitted;<br/>
        (c) for any other purpose which may violate applicable law, banking regulation or SKYLINE TECH LIMITED policy.<br/>
        SKYLINE TECH LIMITED may also terminate or suspend this agreement or the services without notice to the client if SKYLINE TECH LIMITED determines that any information provided by the client is false or misleading or that the client has breached or defaulted under any term of this agreement. SKYLINE TECH LIMITED may terminate this Application Form and Agreement for Services at its sole discretion upon thirty (30) days written notice.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        9. Collinson Forex Limited<br/>
        SKYLINE TECH LIMITED may at its sole discretion utilise the services of Collinson Forex Limited to provide services to the Client. The client consents to this, and agrees that the Collinson Forex Limited standard trading Terms and Conditions shall apply to the provision of such services. The client acknowledges that these terms and conditions which are set out in Appendix 1 to the Application Form and Agreement for Services have been read and that the client is bound by such terms and conditions.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        10. Miscellaneous<br/>
        This Application Form and Agreement for Services, together with any addenda attached hereto, represents the entire agreement of the parties with respect to the subject matter hereof, and may be modified only in writing signed by duly authorized representatives of the parties to be bound thereby. The client may not assign or otherwise transfer its rights and obligations pursuant to this Agreement without SKYLINE TECH LIMITED’s prior written consent. This Application Form and Agreement for Services and the performance of the parties hereunder is governed by the laws of the New Zealand, as applicable, without regard to their conflicts of laws rules which might require the application of the law of another jurisdiction.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">SECTION 3 – ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">SECTION 4 – MODIFICATIONS TO THE SERVICE AND PRICES</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        Prices for our products are subject to change without notice.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">SECTION 5 – PRODUCTS OR SERVICES (if applicable)</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor’s display of any color will be accurate.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">SECTION 6 – OPTIONAL TOOLS</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        You acknowledge and agree that we provide access to such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        We may also, in the future, offer new services and/or features through the website (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">SECTION 7 – THIRD-PARTY LINKS</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        Certain content, products and services available via our Service may include materials from third-parties.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party’s policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">SECTION 8 – USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, ‘comments’), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        1. to maintain any comments in confidence;<br/>
        2. to pay compensation for any comments; or<br/>
        3. to respond to any comments.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">SECTION 9 – PERSONAL INFORMATION</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        Your submission of personal information through the store is governed by our Privacy Policy. To view our Privacy Policy.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">SECTION 10 – ERRORS, INACCURACIES AND OMISSIONS</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">SECTION 11 – PROHIBITED USES</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content:
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        1. for any unlawful purpose;<br/>
        2. to solicit others to perform or participate in any unlawful acts;<br/>
        3. to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances;<br/>
        4. to infringe upon or violate our intellectual property rights or the intellectual property rights of others;<br/>
        5. to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;<br/>
        6. to submit false or misleading information;<br/>
        7. to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet;<br/>
        8. to collect or track the personal information of others;<br/>
        9. to spam, phish, pharm, pretext, spider, crawl, or scrape;<br/>
        10. for any obscene or immoral purpose; or<br/>
        11. to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">SECTION 12 – DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided ‘as is’ and ‘as available’ for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        In no case shall SKYLINE TECH LIMITED, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">SECTION 13 – INDEMNIFICATION</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        You agree to indemnify, defend and hold harmless SKYLINE TECH LIMITED and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">SECTION 14 – SEVERABILITY</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">SECTION 15 – TERMINATION</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">SECTION 16 – ENTIRE AGREEMENT</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">SECTION 17 – GOVERNING LAW</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        These Terms of Service and any separate agreements whereby we provide Services shall be govermed by and construed in accordance with the laws of New Zealand.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">SECTION 18 – CHANGES TO TERMS OF SERVICE</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        You can review the most current version of the Terms of Service at any time at this page.
      </p>
      <p class="policy-text sm:mt-24px mt-2vw">
        We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.
      </p>
    </div>
    <div class="policy-content">
      <div class="title-border"></div>
      <h2 class="policy-content-title">CONTACT INFORMATION</h2>
      <p class="policy-text sm:mt-24px mt-2vw">
        If there are any questions regarding this terms of service you may contact us using the information below.
      </p>
      <ul class="policy-text mt-6vw mb-5vw">
        <li>Phone: 0800 377 366</li>
        <li>From overseas: +64 9 2158966</li>
        <li>Fax: +64 9 3023246</li>
        <li>General Enquiry – info@nzsky.co.nz</li>
        <li>Transactions – enquiry@nzsky.co.nz</li>
        <li class="mt-6vw" style="color: #aaa;">Last Edited on 01/06/2021</li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  components: {}
}
</script>

<style scoped>
.policy-container {
  padding: 6vh 0 0;
}
.policy-header {
  padding: 11vh 0 8vh 0;
  background-color: #163257;
}
.policy-title {
  padding: 1rem 5rem;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}
.title {
  font-size: 52px;
  text-align: left;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 56px;
  margin-top: 20px;
  width: 100%;
  text-shadow: 0px 2px 20px rgba(0, 0, 0, 0.3);
}
.policy-content-top {
  margin-top: 4vw;
}
.policy-content {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  padding: 2rem 5rem 1rem 5rem;
}
.policy-content-title {
  font-size: 28px;
  font-weight: 500;
  color: #163257;
  line-height: 30px;
}
.title-border {
  padding-bottom: 20px;
  width: 70px;
  border-top: 4px solid #f1dba8;
}
.policy-text {
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  line-height: 28px;
}
.subTitle {
  width: 862px;
  height: 64px;
  font-size: 24px;
  font-weight: 300;
  color: #ffffff;
  line-height: 32px;
  margin: 20px auto 0;
  text-align: center;
  text-shadow: 2px 2px gray;
}
.faq-bottom {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  margin: 0 auto;
  padding: 2rem 5rem 0 5rem;
}
.marginTop16 {
  margin-top: 15px;
}
.marginTop48 {
  margin-top: 48px;
}
@media only screen and (max-width: 600px){
.policy-container {
  padding: 0;
}
.policy-header {
  padding: 5vh 0;
  background-color: #163257;
}
.policy-title {
  padding: 1rem 2rem;
}
.title {
  font-size: 2rem;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 3rem;
  margin-top: 7vw;
  text-shadow: 0px 2px 20px rgba(0, 0, 0, 0.3);
}
.policy-content {
  padding: 6vw 2vw;
}
.policy-content-top {
  margin-top: 8vw;
}
.policy-content-title {
  font-size: 18px;
  line-height: 30px;
}
.policy-text {
  font-size: 16px;
}
.title-border {
  padding-bottom: 10px;
  width: 50px;
}
.subTitle {
  width: 100%;
  height: auto;
  font-size: 1rem;
  font-weight: 400;
  color: #ffffff;
  padding: 0 5vw;
  line-height: 1.5rem;
  margin: 3vw auto 0;
  text-align: center;
}
.faq-bottom {
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 5vw;
}
.marginTop16 {
  margin-top: 3vw;
}
.marginTop48 {
  margin-top: 5vw;
}
}
</style>
