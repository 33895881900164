<template>
  <div :class="[isBgGray? 'bg-gray-main': 'bg-white']">
    <div
      class=" block sm:flex sm:pt-108px sm:pr-122px sm:pb-119px sm:pl-100px p-5vw w-full sm:w-1440px m-auto"
    >
      <div class="pt-0 sm:pt-12px w-full sm:w-582px">
        <div class="  hidden sm:block text-2xl sm:text-24px font-600 uppercase text-orange-main">
          Skyline
        </div>
        <div
          class="text-2xl sm:text-48px font-600 text-blue-main leading-3xl sm:leading-56px sm:mt-6px mt-1vw uppercase"
        >
          Business
        </div>
        <div class="text-blue-main text-875rem sm:text-18px sm:mt-24px mt-2vw noto">
          If your business is currently exporting or importing, come talk to one
          of our account management team to provide you a competitive rate and
          professional advice on all of your foreign currency needs.
        </div>
        <!-- <button
          class="font-300 bg-blue-main h-10vw px-5vw sm:px-40px sm:h-60px text-white sm:text-22px text-sm sm:leading-60px leading-10vw text-center rounded-full mt-5vw sm:mt-62px  noto"
        >
          CONTACT US
        </button> -->
      </div>
      <img
        class="w-full sm:w-630px"
        style="display: block"
        src="@/assets/img/business.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isBgGray: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
</style>
