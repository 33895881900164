<template>
  <div class="commission-container">
    <div class="commission-title">Commission</div>
    <div class="commission-content">
      <a-row :gutter="40">
        <a-col :sm="24" :md="12">
          <div class="broker-card">
            <p class="card-title">累计</p>
            <p  class="card-value">${{balance_data.history}} NZD</p>
          </div>
        </a-col>
        <a-col :sm="24" :md="12">
          <div class="broker-card">
            <p class="card-title">已提现</p>
            <p class="card-value" v-if="balance_data">${{balance_data.history - balance_data.balance}} NZD</p>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="10" class="withdraw">
        <a-col :sm="24" :md="14">
          <div class="withdraw-content">
            <a-icon class="wallet-icon" type="wallet" />
            <span class="withdraw-title">可提现：</span>
            <span class="withdraw-value">${{balance_data.balance}} NZD</span>
          </div>
        </a-col>
        <a-col :sm="24" :md="10" class="btn-content">
          <a-button class="withdraw-btn" type="primary" size="large" @click="handleApply">
            申请提现
          </a-button>
        </a-col>
      </a-row>
          <div class=" mt-4 font-500 text-base text-red-400" v-for="item in getPendingWithdraw" :key="item.id">The commission withdraw from {{item. periord}} is pending</div>
    </div>

    <!-- ird 未申请弹窗 -->
    <a-modal
      :title="getIsIndividual?'IRD': 'Bank Invoice'"
      :visible="irdModalVisible"
      @cancel="handleIrdCancel"
      @ok='handleToSetting'
      okText="Setting"
    >
      <p v-if="getIsIndividual">基于新西兰税务要求，提现前需要填写IR330C表格请下载IR330C表格，填写相关信息、签名，请在左侧Settings页面上传</p>
      <p v-else>
        基于新西兰税务要求，提现前需要上传公司银行账号截图，必须显示公司title和公司账号，请在左侧Settings页面上传
      </p>
    </a-modal>
    <!-- 添加银行卡 弹窗 -->
    <a-modal
      title="添加提现账户"
      :visible="addAccountModalVisible"
      @cancel="handleAddAccountCancel"
      okText="Confirm"
      @ok="handleBankSubmit"
    >
      <div>您还需要提供一个银行账户来接收提现，请添加您同名银行账户</div>
      <a-form-model ref="bankForm" :model="bankForm" :rules="rules">
                <a-row :gutter="10">
                    <a-col :xs="12" :md="12">
                        <a-form-model-item label="所属银行" prop="bank_name">
                            <a-input v-model="bankForm.bank_name"/>
                        </a-form-model-item>
                    </a-col>
                    <a-col :xs="12" :md="12">
                        <a-form-model-item label="账户名" prop="account_name">
                            <a-input  v-model="bankForm.account_name"/>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="10">
                    <a-col :span="24">
                        <a-form-model-item label="银行账号" prop="account_number">
                        <BaseBankAccount
                        @handleInpuChange="(e)=>{
                          bankForm.account_number=e
                        }"
                        :account="bankForm.account_number  "/>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
    </a-modal>
    <!-- 确认提现 弹窗 -->
    <a-modal
      title="确认提现"
      :visible="confirmModalVisible"
      @cancel="handleConfirmCancel"
      @ok="handleConfirmWithdraw"
      okText="Confirm"
    >
      <a-row :gutter="10" style="margin-bottom: 30px;">
        <a-col :xs="6" :md="4">
          <p class="confirm-lable">提现</p>
        </a-col>
        <a-col :xs="18" :md="18">
          <a-input prefix="$" suffix="NZD" placeholder="金额" :value="balance_data.balance" disabled />
        </a-col>
      </a-row>
      <div v-if="balance_data && balance_data.bank_account_detail">到账号为{{balance_data.bank_account}}的{{balance_data.bank_account_detail.bank_name}}账户</div>
    </a-modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { brokerWithdraw, brokerAddBank } from '@/api/common.js'
import rules from '@/assets/rules/bankRules.js'
import BaseBankAccount from '@/components/BaseBankAccount.vue'

export default {
  created () {
    this.rules = rules
  },
  components: {
    BaseBankAccount
  },
  data () {
    return {
      irdModalVisible: false,
      addAccountModalVisible: false,
      confirmModalVisible: false,
      bankForm: {
        bank_name: '',
        account_name: '',
        account_number: ''
      }
    }
  },
  computed: {
    ...mapGetters('broker', ['getPendingWithdraw', 'getIsIndividual', 'getIrdStatus', 'getInvoiceStatus']),
    ...mapState('login', ['status', 'attributes']),
    ...mapState('broker', ['commission_current', 'balance_data', 'info_data', 'settlement_data', 'available_balance', 'history_str', 'available_balance_str', 'withdrawed_str'])
  },
  methods: {
    ...mapActions('broker', ['SetBrokerAction']),
    handleBankSubmit (e) {
      this.$refs.bankForm.validate(valid => {
        if (valid) {
          brokerAddBank(this.bankForm).then((res) => {
            this.$message.success('提交成功')
            this.SetBrokerAction().then((res) => {
              this.addAccountModalVisible = false
              this.confirmModalVisible = true
            }).catch(() => {
              this.addAccountModalVisible = false
              this.$message.error('Server is Busy')
            })
          }).catch(() => {
            this.$message.error('Server is Busy')
          })
        } else {
          return false
        }
      })
      // this.editAccountModalVisible = false
    },
    handleApply () {
      if (!(this.getIrdStatus === 'success') && !(this.getInvoiceStatus === 'success')) {
        this.irdModalVisible = true
      } else if (this.balance_data.bank_account === '' || this.balance_data.bank_account === '---') {
        this.addAccountModalVisible = true
      } else {
        this.confirmModalVisible = true
      }
    },
    handleIrdCancel (e) {
      this.irdModalVisible = false
    },
    showAddAccountModal () {
      this.addAccountModalVisible = true
    },
    handleAddAccountCancel (e) {
      this.addAccountModalVisible = false
    },
    showConfirmModal () {
      this.confirmModalVisible = true
    },
    handleConfirmCancel (e) {
      this.confirmModalVisible = false
    },
    handleConfirmWithdraw () {
      if (this.balance_data.balance === 0) {
        this.$message.error('Your available commission is $0.00')
        this.confirmModalVisible = false
        return
      }
      brokerWithdraw().then((res) => {
        this.confirmModalVisible = false
        this.$message.success('提交成功')
        this.SetBrokerAction().then((res) => {
        }).catch(() => {
          this.$message.error('Server is Busy')
        })
      }).catch((res) => {
        this.$message.error(res.data.response)
        this.confirmModalVisible = false
      })
    },
    handleToSetting () {
      this.$router.push({ name: 'Settings' })
    }
  }
}
</script>

<style scoped>
  .commission-title {
    padding: 30px;
    background: #FFFFFF;
    font-size: 24px;
    font-weight: 400;
    color: #080808;
    line-height: 28px;
  }
  .commission-content {
    padding: 0 50px;
    margin: 30px 0px;
  }
  .broker-card {
    padding: 20px;
    height: 100px;
    border-radius: 10px;
    -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    background-color: #fdfdfd;
  }
  .card-title {
    font-weight: 500;
    font-size: 18px;
  }
  .card-value {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }
  .wallet-icon {
    margin-right: 10px;
    font-size: 20px;
    display: inline-flex;
  }
  .withdraw {
    margin-top: 80px;
  }
  .withdraw-content {
    text-align: right;
    padding-right: 80px;
  }
  .withdraw-title {
    font-weight: 500;
    font-size: 18px;
  }
  .withdraw-value {
    padding-left: 10px;
    font-size: 24px;
    font-weight: 500;
    color: red;
  }
  .withdraw-btn {
    width: 120px;
  }
  .confirm-lable {
    line-height: 32px;
    padding-left: 20px;
  }
@media only screen and (max-width: 600px) {
  .commission-content {
    padding: 0 20px;
    margin: 20px 0px;
  }
  .broker-card {
    margin-top: 10px;
  }
  .withdraw {
    margin-top: 50px;
  }
  .withdraw-content {
    text-align: center;
    padding-right: 0px;
  }
  .btn-content {
    margin-top: 20px;
    text-align: center;
  }
}
</style>
